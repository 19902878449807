<script setup>
import {useRoute} from 'vue-router'
const route = useRoute()
const groupId = route.params.group_id
</script>
<template>
  <div class="TG">
    <div class="Heading">
      <div class="container">
        <h1>Ticket Group Config</h1>
      </div>
    </div>
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<style lang="scss">
.route-ticket-group-config-item {
  .TG {
    .container {
      background-color: #fff;
    }

    .Settings__Content__heading {
      border-bottom: 0;

      h2 {
        font-size: 1.5em;
      }
    }
  }
}
</style>
